<template>
  <div id="module-teacher-pay">
    <CoolLightBox :items="coolLightImages" :index="indexViewImg" @close="indexViewImg = null" />
    <div class="row" v-if="item">
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div class="card card-info card-outline shadow">
          <div class="card-body box-profile pb-2">
            <div class="text-center mb-2">
              <b-avatar :src="fullPathFile(item.img)" class="elevation-2" size="120px" variant="default" />
            </div>
            <h3 class="profile-username text-center">{{ item.first_name }} {{ item.last_name }}</h3>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6 border-right">
                <div class="description-block">
                  <h5 class="description-header"><i class="fas fa-birthday-cake mr-1"></i><span>Ngày sinh</span>
                  </h5>
                  <span class="description-text">{{ formatDate(item.dob) }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="description-block">
                  <h5 class="description-header"><i class="fas fa-clipboard-check mr-1"></i><span>Ngày đăng ký</span>
                  </h5>
                  <span class="description-text">{{ formatDate(item.created_at) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card card-info card-outline shadow">
          <!-- <div class="card-header">
              <h3 class="card-title">Thông tin khác</h3>
            </div> -->
          <div class="card-body">

            <strong><i class="fas fa-address-card mr-1"></i> Số CMND/CCCD</strong>

            <p class="text-muted mb-0">{{ item.citizen_id }}</p>

            <hr>

            <strong><i class="fas fa-map-marker-alt mr-1"></i> Địa chỉ</strong>

            <p class="text-muted mb-0">{{ item.address }}</p>

            <hr>

            <strong><i class="fas fa-phone mr-1"></i> Số điện thoại</strong>

            <p class="text-muted">{{ formatPhone(item.phone_number) }}</p>

            <hr>
         
            <strong><i class="fas fa-calendar-check mr-1"></i> Ngày vào làm</strong>

            <p class="text-muted mb-0">{{ item.starting_date }}</p>

            <hr>
            
            <strong><i class="fas fa-briefcase mr-1"></i> Tỉnh/Thành phố công tác</strong>

            <p class="text-muted">{{ getTextOption(item.province_id, provinceOptions)}}</p>

          </div>
        </div>
      </div>

      <div class="col-lg-9 col-md-6 col-sm-12">
        <div class="card shadow">
          <div class="card-body p-0">
            <table class="table size-website">
              <thead>
                <tr>
                  <th>
                    Tháng
                  </th>
                  <th>
                    Sân vận động
                  </th>
                  <th>
                    Lịch dạy
                  </th>
                  <th style="width: 79px;">
                    <button class="btn btn-success btn-sm btn-block">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody v-if="items && items.items">
                <tr v-for="(item, index) in items.items" :key="index">
                  <td class="text-center">
                    <div class="text-nowrap text-bold">{{ item.month }}<span v-if="item.year">/{{ item.year }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="pl-1">{{ item.name }}</div>
                    <div class="pl-1"><i class="text-sm">{{ item.address }}</i></div>
                  </td>
                  <td>
                    <div class="btn-group">
                      <button class="btn btn-info btn-sm"><i class="fas fa-edit"></i></button>
                      <button class="btn btn-default btn-sm"><i class="far fa-trash-alt"></i></button>
                    </div>
                    <button class="btn btn-xs btn-outline-info btn-block mt-2">
                      <span>Học bù</span>
                    </button>
                  </td>
                </tr>
              </tbody>
              <Paging v-if="items" :items="items" column="7" :callBack="pagingBy" />
            </table>
            <table class="table size-mobile">
              <thead>
                <tr>
                  <th style="width: 79px;">
                    <button class="btn btn-success btn-sm btn-block">
                      <i class="fas fa-plus-circle"></i>
                    </button>
                  </th>
                  <th>Quá trình dạy</th>
                </tr>
              </thead>
              <tbody v-if="items && items.items">
                <tr v-for="(item, index) in items.items" :key="index">
                  <td>
                    <div class="btn-group">
                      <button class="btn btn-info btn-sm"><i class="fas fa-edit"></i></button>
                      <button class="btn btn-default btn-sm"><i class="far fa-trash-alt"></i></button>
                    </div>
                    <button class="btn btn-xs btn-outline-info btn-block mt-2">
                      <span>Học bù</span>
                    </button>
                  </td>
                  <td>
                    <div class="text-nowrap text-bold">{{ item.month }}<span v-if="item.year">/{{ item.year }}</span>
                    </div>
                    <div><i class="fas fa-futbol mr-2"></i>{{ item.name }}</div>
                    <div><i class="text-sm">{{ item.address }}</i></div>
                    <div>
                      <i class="fas fa-dollar-sign text-sm mr-2"></i>
                      <span class="text-nowrap text-info">{{ formatNumber(item.money, true) }}</span>
                      <span :class="'text-nowrap text-sm ml-3' + (item.status == 0 ? ' text-bold text-danger' : '')"
                        v-if="item.money > 0">
                        ({{ getTextOption(item.status, statusPayOptions) }})
                      </span>
                      <span class="text-nowrap text-sm ml-3" v-else>
                        (Miễn phí)
                      </span>
                    </div>
                    <div class="div-divider">
                      <hr class="hr-divider" />
                      <span class="text-divider text-sm">Lịch học</span>
                    </div>
                    <div v-for="(cal, i) in item.calendars" :key="'calendars-' + i" class="text-nowrap">
                      {{ getTextOption(cal.day_in_week, dayInWeekOptions) }} - {{ cal.start_time }}
                    </div>
                    <div class="div-divider" v-if="item.learn_mores && item.learn_mores.length > 0">
                      <hr class="hr-divider" />
                      <span class="text-divider text-sm">Học bù</span>
                    </div>
                    <div v-for="(more, i) in item.learn_mores" :key="'learn_mores-' + i" class="text-nowrap">
                      {{ formatDate(more.date) }}
                      <span class="text-sm ml-1">
                        (<i v-if="more.status == 1">Đã học</i><i v-else>Không học</i>)
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
              <Paging v-if="items" :items="items" column="7" :callBack="pagingBy" />
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
import ApiTeacher from "../../services/apiTeacher";
import { dayInWeekOptions, statusPayOptions } from "../../common/constants";
import { getAdminAuthen, getProvinces } from "../../common/storage";
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  components: {
    CoolLightBox,
    Paging: () => import("../components/Paging.vue"),
  },
  data() {
    return {
      userLogin: null,
      coolLightImages: [],
      indexViewImg: null,
      item: null,
      statusPayOptions: statusPayOptions,
      dayInWeekOptions: dayInWeekOptions,
      items: null,
      paging: null,
      orders: [{ column: "month_year", type: "desc" }],
      provinceOptions: []
    };
  },
  methods: {
    async getByTeacher() {
      if (!this.$route.params) return;
      this.showLoading(true);
      const data = await ApiTeacher.get(this.$route.params.teacherId);
      this.showLoading(false);
      this.coolLightImages = [];
      if (data) {
        this.item = data;
        this.coolLightImages = [this.fullPathFile(data.img)];
      }
    },
    pagingBy(paging) {
      this.paging = paging;
    },
    orderBy(orders) {
      this.orders = orders;
    },
    async getProvince() {
      const provinces = getProvinces();
      if (provinces && provinces.length > 0) this.provinceOptions = provinces.map(item => {
        return { value: item.province_id, text: `${item.type} ${item.name}` };
      });
    }
  },
  mounted() {
    this.userLogin = getAdminAuthen();
    this.getByTeacher();
    this.getProvince();
  }
};
</script>
  